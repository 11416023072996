@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --white: #ffffff;
  --black: #000000;
  --turquoise: #00ACAC;
  --redish: #E45462;
  --pink: #ec868f;
  --greyish: #072734;
  --light-grey: #707070;
  --light-red: #F25E5E;
  --red: #E4492D;
  --blackish: #210E09;
  --white-greyish: #FAFAFA;
  --kinda-light-grey: #ACACAC;
  --light-blue: #30C2FF;
  --surface: #18130B;
  --on-surface: #201B13;
  --thijs: #18130B;
  --primary: #F1BE6D;
  --disabled-primary: #9B8F80;
  --orange: #F6A11B;
  --on-surface-tonal: #3A342B;
  --button-primary-text: #432C00;
  --page-bg: #1E1E1E;
  --text-info: #ECE1D4;
  --text-beige: #D2C4B4;
  --text-light-blue: #059AD2;
  --text-grey: #9B8F80;
  --light-peach: #FFDDAE;
}

body {
  direction: rtl;
  font-family: 'Heebo', sans-serif;
  text-align: right;
  background-color: var(--surface);
  color: var(--text-info)
}

a {
  text-decoration: auto;
}

.bg-turquoise {
  background-color: var(--turquoise) !important;
}

.bg-light-red {
  background-color: var(--light-red);
}

.bg-text-light-blue {
  background-color: var(--primary);
  color: var(--on-surface) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-surface-tonal {
  background-color: var(--on-surface-tonal) !important;
}

.color-turquoise {
  color: var(--turquoise);
}

.color-orange {
  color: var(--orange);
}

.color-white {
  color: var(--white);
}

.color-kinda-light-grey {
  color: var(--kinda-light-grey);
}

.color-white-greyish {
  color: var(--white-greyish);
}

.color-light-grey {
  color: var(--light-grey);
}

.color-black {
  color: var(--black);
}

.color-light-peach {
  color: var(--light-peach);
}

.color-primary-text {
  color: var(--button-primary-text);
}

.color-on-surface {
  color: var(--on-surface);
}

.color-text-info {
  color: var(--text-info);
}

.color-primary {
  color: var(--primary);
}

.color-greyish {
  color: var(--greyish);
}

.color-red {
  color: var(--red);
}

.h-8 {
  height: 8% !important;
}

.page-navbar {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  color: var(--on-surface-tonal);
  justify-content: center;
  min-height: 15%;
  background-color: var(--primary);
  align-items: center;
}

.page-navbar .textual-link {
  font-weight: 500;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-19 {
  font-size: 19px;
}

.font-size-21 {
  font-size: 21px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-31 {
  font-size: 31px;
}

.btn {
  cursor: pointer;
}

.btn-primary {
  background-color: var(--primary);
  color: var(--on-surface-tonal);
  border: 0;
  border-radius: 100px;
  font-weight: 500;
  font-size: 18px;
}

.btn-primary-link {
  width: fit-content;
  border-radius: 21px;
  background-color: var(--primary);
  color: var(--on-surface-tonal);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  margin: 0.5rem;
}

.btn-dismiss {
  border-radius: 30px;
  color: var(--primary);
  border: 2px solid var(--primary) !important;
}

.btn-submit,
.btn-delete {
  border-radius: 30px;
  cursor: pointer;
}

.btn-submit,
.btn-submit:hover,
.btn-submit:active {
  background-color: var(--primary) !important;
  color: var(--on-surface-tonal) !important;
}

.btn-delete:hover,
.btn-delete {
  background-color: unset !important;
  color: var(--red);
  border-color: var(--red);
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.textual-link:hover {
  text-decoration: none !important;
  color: var(--white);
}

.modal-backdrop.show {
  opacity: .6;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.justify-content-space-between {
  justify-content: space-between;
}

.border-primary {
  border: 1px solid var(--primary) !important;
}

#navbar-menu {
  height: 19px;
}

.mb-7 {
  margin-bottom: 7rem;
}

.mb-10 {
  margin-bottom: 10rem;
}

.hidden {
  display: none !important;
}

.active {
  text-decoration: underline;
  text-decoration-color: var(--on-surface);
}

img, div,
input:focus, textarea:focus {
  outline: none;
}

a,
a.focus {
  outline: none !important;
}

.no-data-notice-box {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-55 {
  height: 55% !important
}

.success-icon {
  max-width: 50%;
}

.pointer {
  cursor: pointer;
}

.modal-header {
  border-bottom: 0 !important;
}

.modal-title {
  color: var(--primary);
}

.modal-content {
  background-color: var(--on-surface);
  border: 2px solid var(--primary)
}

.modal-footer {
  border-top: 0;
}

.number-of-participants {
  font-size: 24px;
  font-weight: 500;
  color: var(--primary)
}

hr {
  border-top: 1px solid rgb(0 0 0 / 0%);
}

fa-icon {
  cursor: pointer;
}

.desktop-content-box {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.alert-danger {
  color: var(--red);
  border: 1px solid var(--white);
  background-color: transparent;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .desktop-content-box {
    max-width: 650px;
    margin: 0 auto;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}
